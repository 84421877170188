import React, { useEffect, useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
//components
import { BaseBodyStyle, SubmitButton } from 'src/common/StylesComponents';
import CustomerStatusList from 'src/views/customer/common/CustomerStatusList';
import { ConstructionLog } from './children';
import { CustomerDetailHeader, CustomerInput } from 'src/views/customer/common';
import Button from 'src/common/Button';
import DeleteConfirmModal from 'src/views/settings/children/DeleteConfirmModal';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { setConstructionAndCustomerSupportData, setSelectedDetailedCustomerData } from 'src/redux/actions/CustomerDataHandler';
import { setConstructionListData, setSelectedConstructionId, removeSelectedConstructionDetailData } from 'src/redux/actions/ConstructionDataHandler';
import { setSelectedCustomerSupportData } from 'src/redux/actions/CustomerSupportDataHandler';
import { setCustomerSupportMasterSelectionData } from 'src/redux/actions/MasterDataHandler';
import { setCustomerSupportData } from 'src/redux/actions/CustomerSupportDataHandler';
//functions
import StatusFunctions from 'src/functions/StatusFunctions';
import { CustomerDeleteFunction } from 'src/functions/CustomerFunctions';
import { sendLogData, logCategory } from 'src/functions/LogFunctions';
//style and config
import { BreakPoints } from 'src/configs/StyleConfig';

const Navigation = {
  Edit: "Edit",
  ConstructionRegister: "ConstructionRegister",
  CustomerSupportRegister: "CustomerSupportRegister",
};

const sortingOption = [
  { value: 0, label: '工事完了日（新）' },
  { value: 1, label: '工事完了日（古）' },
  { value: 2, label: '対応日（新）' },
  { value: 3, label: '対応日（古）' },
];

const CustomerDetail = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { employee_name, mtb_sales_office_id, token, mtb_enterprise_id } = useSelector(state => state.auth);
  const { enterpriseData, salesOfficeData } = useSelector(state => state.master);
  const { selectedCustomerId, selectedCustomerData, constructionAndCustomerSupportDataList } = useSelector(state => state.customer);
  const { customerSupportList } = useSelector(state => state.customerSupport);
  const { customerConstructionList } = useSelector(state => state.construction);

  const [customerInfo, setCustomerInfo] = useState(
    {
      customer_name: '', customer_kana: '', customer_postal_code: '', customer_address1: '', customer_address2: '', customer_phone1: '', customer_phone2: '',
      memo: '', status: [
        { id: 0, status: '対応不可', isSelected: false },
        { id: 1, status: '信販不可', isSelected: false },
        { id: 2, status: '転居', isSelected: false },
        { id: 3, status: 'クレーム', isSelected: false }
      ],
      created_employee_name: '', updated_employee_name: '', mtb_enterprise_id: null, mtb_sales_office_id: null, customer_code: '', id: null
    }
  );
  const [enterpriseName, SetEnterpriseName] = useState("");
  const [salesOfficeOption, setSalesOfficeOption] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalRequired, setModalRequired] = useState(false);
  const printRef = useRef(null);
  const [sortType, setSortType] = useState(0);

  useEffect(() => {
    const getCustomerRelatedData = async () => {
      //選択された顧客IDに基づきリストから顧客データを選択
      dispatch(setSelectedDetailedCustomerData(selectedCustomerId));
      //顧客に紐づく顧客対応履歴と工事履歴の取得
      dispatch(setCustomerSupportData(selectedCustomerId)); //顧客対応履歴
      dispatch(setConstructionListData(selectedCustomerId)); //工事履歴
    };

    if (selectedCustomerId && mtb_sales_office_id) {
      dispatch(removeSelectedConstructionDetailData());
      getCustomerRelatedData(selectedCustomerId);
      //対応履歴内容を取ってくる関数を呼び出す
      dispatch(setCustomerSupportMasterSelectionData(mtb_sales_office_id, 0));
      dispatch(setCustomerSupportMasterSelectionData(mtb_sales_office_id, 1));
      dispatch(setCustomerSupportMasterSelectionData(mtb_sales_office_id, 2));
    }

  }, [dispatch, mtb_sales_office_id, selectedCustomerId]);

  useEffect(() => {
    const setCustomerDetailData = () => {
      // 顧客詳細の初期化
      if (selectedCustomerData.length === 0) return;
  
      const _customerInfo = { ...customerInfo };
      const customerData = { ...selectedCustomerData };
      Object.keys(_customerInfo).forEach((key) => {
        if (key === 'status') {
          _customerInfo[key] = StatusFunctions.arrayStatusToFormattedArray(customerData['status']);
        } else if (key === 'created_employee_name' || key === 'updated_employee_name') {
          _customerInfo[key] = employee_name;
        } else {
          _customerInfo[key] = customerData[key];
        }
      });
      setCustomerInfo(_customerInfo);
    };

    const salesOptionInit = () => {
      // 営業所の選択肢の初期化
      const _options = [];
      if (Object.keys(salesOfficeData).length === 0) return _options;
  
      Object.keys(salesOfficeData).forEach((key) => {
        const json = {
          value: key,
          label: salesOfficeData[key].sales_office_name
        };
        _options.push(json);
      });
      setSalesOfficeOption(_options);
    };

    const setCustomerStatusData = () => {
      // 企業名の初期化
      const customerData = { ...selectedCustomerData };
      const { mtb_enterprise_id } = customerData;
      const _enterpriseName = enterpriseData[mtb_enterprise_id] && enterpriseData[mtb_enterprise_id].enterprise_name;
      salesOptionInit();
      SetEnterpriseName(_enterpriseName);
    };

    if (customerSupportList && customerConstructionList && selectedCustomerData) {
      //工事履歴と顧客対応履歴をフェッチしてきた際に対応日でソートする関数の呼び出しと顧客詳細データの初期化か関数呼び出し
      dispatch(setConstructionAndCustomerSupportData());
      setCustomerDetailData();
      setCustomerStatusData();
      setIsLoading(false);
    }
  }, [dispatch, customerSupportList, customerConstructionList, selectedCustomerData, enterpriseData, salesOfficeData, employee_name]);

  const handleNavigation = useCallback((navigateTo) => {
    //顧客編集ページ、工事登録ページ、顧客対応登録ページへの推移を行う関数
    const currentPath = location.pathname;
    switch (navigateTo) {
      case 'Edit':
        history.push({ pathname: `${currentPath}/edit`, state: { data: customerInfo, enterprise: enterpriseName, options: salesOfficeOption } });
        return;
      case 'ConstructionRegister':
        history.push({ pathname: '/construction-list/register', state: { previousPath: currentPath } });
        return;
      case 'CustomerSupportRegister':
        history.push({ pathname: '/customer-support/register' });
        return;
      default:
        return;
    }
  }, [history, location.pathname, customerInfo, enterpriseName, salesOfficeOption]);

  const navigateToDetail = useCallback((selectedIndex) => {
    //顧客対応詳細、工事詳細への推移
    //選択されたインデックスを元にして工事か顧客対応かチェック、その後にidを元にそれぞれの詳細ページへ推移
    const selectedTableData = constructionAndCustomerSupportDataList[selectedIndex];
    const status = selectedTableData.status && selectedTableData.status;
    if (!status) return;

    if (status === "工事") { //工事
      const selectedId = selectedTableData.dtb_construction_id;
      dispatch(setSelectedConstructionId(selectedId));
      history.push(`/construction-list/${selectedId}`);
    } else { //対応履歴
      const selectedId = selectedTableData.dtb_customer_support_id;
      dispatch(setSelectedCustomerSupportData(selectedId));
      history.push(`/customer-support/${selectedId}`);
    }
  }, [dispatch, history, constructionAndCustomerSupportDataList]);

  const onDeleteButtonPressed = useCallback(async () => {
    //顧客詳細ページの消去ボタンプレス後に着火する関数
    //顧客データを消去、顧客ログ登録（mtb_customer_log）、全体ログに登録（dtb_logs）
    const customerDeletingFunction = new CustomerDeleteFunction({ customer: selectedCustomerData, mtb_enterprise_id: mtb_enterprise_id, employee_name: employee_name, token: token });
    await customerDeletingFunction.delete();
    setModalRequired(false);
    history.replace('/customer-list');
  }, [history, selectedCustomerData, mtb_enterprise_id, employee_name, token]);

  const onPrintButtonPressed = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => registerPrintLog(),
  });

  const registerPrintLog = async () => {
    const { customer_name } = selectedCustomerData;
    const customerPrintLog = {
      id: null,
      category: logCategory.print,
      remarks: `顧客詳細: ${customer_name}`,
      mtb_enterprise_id: mtb_enterprise_id,
      table_type: null,
      log_id: null,
      created_employee_name: employee_name,
      updated_employee_name: employee_name
    };
    const json = [customerPrintLog];
    await sendLogData(json, token);

  }

  const onRegisterButtonPressed = useCallback(() => {
    history.push('/customer-list/register');
  }, [history]);

  const SortSelected = useCallback((e) => {
    setSortType(e.target.value ? parseInt(e.target.value) : 0);
  }, [setSortType]);

  if (isLoading) return <p>Loading....</p>

  // 登録された順番に変更
  if (sortType === 0) {
    // 完了新しい順番
    constructionAndCustomerSupportDataList.sort(function (a, b) {
      if (a.status === '工事' && b.status === '工事') {
        return new Date(b.construction_end_date) - new Date(a.construction_end_date);
      } else if (a.status=== '工事' && b.status === '対応履歴') {
        return new Date(b.support_complete_date) - new Date(a.construction_end_date);
      } else if (a.status === '対応履歴' && b.status === '工事') {
        return new Date(b.construction_end_date) - new Date(a.support_complete_date);
      } else {
        return new Date(b.support_complete_date) - new Date(a.support_complete_date);
      }
    });
  } else if (sortType === 1) {
    // 完了古い順番
    constructionAndCustomerSupportDataList.sort(function (a, b) {
      if (a.status === '工事' && b.status === '工事') {
        return new Date(a.construction_end_date) - new Date(b.construction_end_date);
      } else if (a.status === '工事' && b.status === '対応履歴') {
        return new Date(a.construction_end_date) - new Date(b.support_complete_date);
      } else if (a.status === '対応履歴' && b.status === '工事') {
        return new Date(a.support_complete_date) - new Date(b.construction_end_date);
      } else {
        return new Date(a.support_complete_date) - new Date(b.support_complete_date);
      }
    });
  } else if (sortType === 2) {
    // 対応新しい順番
    constructionAndCustomerSupportDataList.sort((a, b) => new Date(b.support_date) - new Date(a.support_date));
  } else if (sortType === 3) {
    // 対応古い順番
    constructionAndCustomerSupportDataList.sort((a, b) => new Date(a.support_date) - new Date(b.support_date));
  };

  return (
    <BaseBodyStyle main ref={printRef}>
      <CustomerDetailHeader
        onPrintButtonPressed={onPrintButtonPressed}
        onRegisterButtonPressed={onRegisterButtonPressed}
      />
      <CustomerStatusList
        statusList={customerInfo.status}
        enterprise={enterpriseName}
        customerCode={customerInfo.customer_code}
        dropdownSelection={salesOfficeOption}
        mtb_sales_office_id={customerInfo.mtb_sales_office_id}
        isEditable={false}
        isRegistering={false}
      />
      <InputContainer>
        <HeaderButtonContainer>
          <div style={{ paddingRight: '8px' }}>
            {/* #604 */}
            {/* <Button isDeleting={true} text={'消去'} onClick={() => setModalRequired(true)} width={120} /> */}
            <Button disabled={true} text={'消去'} onClick={() => setModalRequired(true)} width={120} />
          </div>
          <div>
            {/* #604 */}
            {/* <Button text={'編集'} onClick={() => handleNavigation(Navigation.Edit)} width={120} /> */}
            <Button disabled={true} text={'編集'} onClick={() => handleNavigation(Navigation.Edit)} width={120} />
          </div>
        </HeaderButtonContainer>
        <CustomerInput customerInfo={customerInfo} isEditable={false} isRegistering={false} />
        <ButtonContainer>
          {/* #604 */}
          {/* <SubmitButton onClick={() => handleNavigation(Navigation.ConstructionRegister)}>新規工事登録</SubmitButton> */}
          {/* <SubmitButton onClick={() => handleNavigation(Navigation.CustomerSupportRegister)}>対応履歴登録</SubmitButton> */}
          <Button disabled={true} onClick={() => handleNavigation(Navigation.ConstructionRegister)} text={'新規工事登録'} />
          <Button disabled={true} onClick={() => handleNavigation(Navigation.ConstructionRegister)} text={'対応履歴登録'} />
        </ButtonContainer>
        <div style={{ marginLeft: 'auto', marginTop: '4px' }}>
          <span style={{ color: '#3f3f3f', fontWeight: 'bold', padding: '6px 8px' }}>並び替え</span>
          <select value={sortType} style={{ height: "28px", margin: '4px 36px 0px 4px' }} onChange={SortSelected}>
            {sortingOption.map((key, index) => {
              return (
                <option key={index} value={key.value}>{key.label}</option>
              )
            })}
          </select>
        </div>
      </InputContainer>
      <ConstructionLog data={constructionAndCustomerSupportDataList} navigation={navigateToDetail} />
      <DeleteConfirmModal
        modalRequired={modalRequired}
        onCloseModalButtonPressed={() => setModalRequired(false)}
        onConfirmButtonPressed={onDeleteButtonPressed}
      />
    </BaseBodyStyle>
  )
};

const InputContainer = styled.div`
  width: 100%;
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media screen and (max-width: ${BreakPoints.md}) {
    padding: 40px 0;
  }
`

const HeaderButtonContainer = styled.div`
  width: 98%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ButtonContainer = styled.div`
  width: 96%;
  margin: 8px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

export default CustomerDetail;