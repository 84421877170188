import React, { useEffect, useState, useCallback, useRef, memo } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
// styles & config
import { BaseBodyStyle } from 'src/common/StylesComponents';
import { Colors } from 'src/configs/StyleConfig';
//components
import Button from 'src/common/Button';
//redux
import { useSelector } from 'react-redux';
//functions
import DateFunctions from 'src/functions/DateFunctions';
import { FileUploadGetFunctions } from 'src/functions/FileUploadFunctions';
//static files
import PDF_icon from 'src/static/pdf.png';

// ある工事に付属している全ての取り込みファイルの取得
// --- 登録時；
// 取り込まれたファイルのリスト化
// --- アップデート時：
// 取り込まれたファイル、既存のファイルのリスト化
// 既存にあったファイルで消去されたファイルのリスト化

const FileUpload = () => {

  const history = useHistory();
  const location = useLocation();
  const { employee_name, token } = useSelector(state => state.auth);
  const { selectedConstructionData } = useSelector(state => state.construction);

  const [uploadedFileList, setUploadedFileList] = useState([]);
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const constructionData = useRef({ dtb_construction_id: null, employee_name: null, mtb_customer_id: null });

  useEffect(() => {
    if (selectedConstructionData && employee_name && token) {
      constructionData.current.dtb_construction_id = selectedConstructionData.id;
      constructionData.current.mtb_customer_id = selectedConstructionData.mtb_customer_id;
      constructionData.current.employee_name = employee_name
      initFileUploadData();
      setIsLoading(false);
    }
  }, [selectedConstructionData, employee_name, token]);

  const initFileUploadData = async () => {
    //DBからアップロードされた画像のフェッチ
    const fileUploadGetFunctions = new FileUploadGetFunctions({ dtb_construction_id: constructionData.current.dtb_construction_id, token: token })
    const response = await fileUploadGetFunctions.getFiles();
    if (response.error) {
      setErrorText(response.error);
    } else {
      const { data } = response;
      setUploadedFileList(data);
      setErrorText('');
    }
  }

  const onEditButtonPressed = useCallback(() => {
    const currentPath = location.pathname;
    history.push({
      pathname: `${currentPath}/edit`, state: {
        uploadedFileList: uploadedFileList,
        constructionData: constructionData.current,
        prevPath: currentPath
      }
    });
  }, [history, location, uploadedFileList, constructionData]);

  const Row = memo((props) => {
    const { data, index } = props;
    return (
      <div style={{ listStyle: 'none', height: 100, width: '99%', padding: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px dotted gray' }}>
        <div style={{ width: '60%', display: 'flex', flexDirection: 'row', }}>
          <a style={{ width: 140 }} href={data.file_url} title={data.file_name} download={data.file_name}>
            {
              data.file_type === "application/pdf" ?
                <IMG cursor={'pointer'} src={PDF_icon} />
                :
                <IMG cursor={'pointer'} src={data.file_url} />
            }
          </a>
          <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 4 }}>
            <span style={{ height: '70%', display: 'flex', alignItems: 'center', fontSize: 20, fontWeight: '500', color: Colors.textColor }}>
              {data.file_name}
            </span>
            <span style={{ height: '30%', display: 'flex', alignItems: 'start', fontSize: 12, fontWeight: '500', color: Colors.grayText }}>
              {
                data.created_at ?
                  `アップロード日時：${DateFunctions.ISOStringDateToLocalDate(data.created_at)}`
                  :
                  'まだアップロードしていません。'
              }
            </span>
          </div>
        </div>
      </div>
    )
  });

  if (isLoading) {
    <BaseBodyStyle main />
  };

  return (
    <BaseBodyStyle main>
      <div style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 40, }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: 12 }}>
          <div style={{ marginTop: '8px', fontWeight: 'bold' }}>画像クリックでダウンロード</div>
          {/* #604 */}
          {/* <Button text={'編集'} onClick={onEditButtonPressed} /> */}
          <Button disabled={true} text={'編集'} onClick={onEditButtonPressed} />
        </div>
        <div style={{ width: '100%', minHeight: 100, height: 'auto', maxHeight: '80vh', border: '1px solid gray', overflowY: 'auto' }}>
          {
            uploadedFileList.map((row, index) => {
              return (
                <Row data={row} index={index} key={index.toString()} />
              )
            })
          }
        </div>
      </div>
      <span style={{ color: Colors.errorText }}>
        {errorText && errorText}
      </span>
      <ButtonMenu>
        <Button text="工事詳細へ戻る" onClick={() => history.goBack()} />
      </ButtonMenu>
    </BaseBodyStyle>
  )
};

export const ButtonMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
`
export const IMG = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: ${props => props.cursor ? props.cursor : 'unset'};
`

export default FileUpload;